<template>
    <div ref="chartContainer" class="chart" style="height: 100%; position: relative;">
    </div>
</template>
<script>

import Anychart from 'anychart';
import './AnychartThemes/market_forecast.js'

// THIS CHART IS USED FOR EXPORT TO WORD

export default {
    props: [
        'data',
        'year',
        'firstYear',
        'lastYear',
        'topicName',
        'segmentationName',
        'valueDisplayOption',
        'FYValues',
        'LYValues',
        'index1',
        'exportToWord',
        'decimalsOption',

        'toChartSegmItemsLength',
        'countLength',
        'chartIndex',
    ],
    emits: ['next-step','addChartsForExportWord','finishLoading'],
    name: 'VueAnychartVersusFigure2Export',
    components:{
        // Button,
    },
    data() {
        return {
            chart: null,
            pie:null,
            // stage
            stage: null, 
            // chart
            chartFY: null,
            chartLY: null,

            // totals
            totalFY: null,
            totalLY: null,
        }
    },

    mounted() {
        this.sumYearsValue()
        this.init();
        // this.$emit('next-step');
        
        // VALIDATE IF CAME FROM EXPORT TO WORD
        if(this.exportToWord){
            this.addChartsForWord();
            if(this.toChartSegmItemsLength -1 === this.index1 && this.countLength -1 === this.chartIndex ){
                this.endLastStepForExportToWord();
            }
        }
    },
    computed: {
        topic() {
            return this.$store.getters.obtainTopic;
        },
    },
    methods: {
        removeSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeSeriesAt(0);
            }
        },
        removeAllSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeAllSeries();
            }
        },
        addSeries(data) {
            this.delegateMethod('addSeries', data);
        },
        // delegateMethod(name, data) {
        //     if (!this.chart) {
        //         warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this);
        //         return
        //     }

        //     return this.chart[name](data)
        // },

        toFixDecimate3(cell) {
            if(this.decimalsOption === '0.'){
                if(Number.isInteger(cell)){
                    return cell
                }
                else{
                    return Math.round(cell);
                }
            }
            if(this.decimalsOption === '.0'){
                return this.roundTo(cell, 1).toFixed(1) 
            }
            if(this.decimalsOption === '.00'){
                return this.roundTo(cell, 2).toFixed(2)     
            }
            if(this.decimalsOption === '.000'){
                return this.roundTo(cell, 3).toFixed(3)     
            }
        },

        roundTo(num, decimals) {
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor)/factor;
        },

        formatNumber(num){
            if(num){
                // split int and decimals of number
                let [int , decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return num
            }
        },

        sumYearsValue(){// get total of totalFy and totalLY
            let fy = this.FYValues.reduce((acc, value)=> acc + parseFloat(value),0);
            fy = this.toFixDecimate3(fy);

            
            this.totalFY = this.formatNumber(fy)

            let ly = this.LYValues.reduce((acc, value)=> acc + parseFloat(value),0);
            ly = this.toFixDecimate3(ly)
            this.totalLY = this.formatNumber(ly)

            // HardCoded test
            // this.totalFY= 20
            // this.totalLY= 10
        },

        init(){
            let _Anychart = Anychart
            this.chart = _Anychart;

            // Apply the theme (if applicable)
            this.chart.theme('marketForecast');

            // create a stage
            // this.stage = this.chart.graphics.create("container");
            this.stage = this.chart.graphics.create(this.$refs.chartContainer,1500,800);
            // this.stage = this.chart.graphics.create(this.$refs.chartContainer);

            const currentYear = new Date().getFullYear();

            // LICENSE KEY
            this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c'); 

            this.stage
                .credits()
                .enabled(true)
                .url('https://www.marketforecast.com')
                .logoSrc('https://www.marketforecast.com/favicon.ico')
                .text(
                    `© Market Forecast ${currentYear}`
                );

            // Data for the charts
            // let dataSet = this.chart.data.set(
            //     [
            //         ['North America', 9097, 15253, 80008],
            //         ['Europe', 14694, 25540, 180600],
            //         ['APAC', 5917, 12657, 64745],
            //         ['Middle-East & Africa', 14019, 23990, 115827],
            //         ['Latin America', 1073, 2256, 13115]
            //     ]
            // );

            let dataSet = this.chart.data.set(this.data);

            let SeriesFY = dataSet.mapAs({ x: 0, value: 1 });
            let SeriesLY = dataSet.mapAs({ x: 0, value: 2 });

            // Create and configure the standalone title
            //// stageTitle title // hide stageTitle Title
            // let stagetitle = this.chart.standalones.title();

            // stagetitle
            //     .enabled(true)
            //     .useHtml(true)
            //     .padding([10, 0, 10, 0]) // Add padding to create space around the title
            //     .text(
            //         `<span style="font-size: 16px; font-weight: bold;">${this.topicName} Market Forecast to ${this.lastYear} by ${this.segmentationName}</span>`
            //     );
            // Set the container for the title and draw it
            // stagetitle.container(this.stage);
            // stagetitle.draw();
            //// endHidestageTitle
            
            // 
            // create and configure the first pie chart (smaller)
            this.chartFY = this.chart.pie(SeriesFY);

            // Set chart margin settings
            this.chartFY.padding(5, 5, 5, 5);

            // Configure labels
            this.chartFY.labels().position('outside');
            this.chartFY.labels().fontSize(17);
            this.chartFY.labels().format(
                function(){
                    // get Actual value of the text
                    let currentLabel = this.getData('x');
                    // let value = this.getData('value');
                    let percentage = this.getStat('percentValue').toFixed(2)+'%';
                    return currentLabel+ " " + percentage; 
                }
            )

            // set chart legend settings
            this.chartFY.legend().enabled(false);
            
            // Adjust the bounds to make chartFY smaller
            // validation if chartFy is smaller
            const totalFY = Number(this.totalFY.replace(',',''));
            const totalLY = Number(this.totalLY.replace(',',''));

            if(totalFY < totalLY ){
                this.chartFY.bounds(0, "20%", "44.9%", "60%");
                    // this.chartLY.bounds("40%", "10%", "60%", "80%");
                //TODO  change font size for Validation 
                // this.chartFY.labels().fontSize(10);
                
                // console.log('fy menor ly');
            }
            else{
                this.chartFY.bounds(0, "10%", "60%", "80%");
                // this.chartLY.bounds(0, "20%", "40%", "60%");
                //TODO  change font size for Validation 
                // this.chartFY.labels().fontSize(12);
                // console.log('fy mayor ly');
            }

            this.chartFY.background().stroke(null);
            this.chartFY.innerRadius("30%");

            const FY = this.firstYear
            const valueDisplayOption = this.valueDisplayOption

            // chartFY Title
            this.chartFY
                .title()
                .enabled(true)
                .text(`${FY} US$ ${this.totalFY} ${this.valueDisplayOption}`)
                .orientation("bottom")
                .align("center")
                .fontSize(20)
            

            // // create and configure a label
            // let labelFY = this.chart.standalones.label();
            // labelFY.text(FY);
            // labelFY.width("100%");
            // labelFY.height("100%");
            // labelFY.fontColor("#003A70");
            // labelFY.fontWeight("bold");
            // labelFY.hAlign("center");
            // labelFY.vAlign("middle");
            
            // this.chartFY.center().content(labelFY);     // set the label as the center content

            this.chartFY.label()
                .enabled(true)
                .text(FY) 
                .position("center")
                .anchor("center") 
                .hAlign("center") 
                .vAlign("middle") 
                .offsetY(-10)
                .fontColor("#003A70")
                .fontWeight("bold") 
                .fontSize(25); 


            // Configure tooltips
            this.chartFY.tooltip().title().fontColor("#FFFFFF"); // Set tooltip text color
            this.chartFY.tooltip().title().fontSize(14); // Set tooltip font size
            this.chartFY.tooltip().fontColor("#FFFFFF"); // Set tooltip text color
            this.chartFY.tooltip().fontSize(12); // Set tooltip font size
            this.chartFY.tooltip().padding(10); // Set tooltip padding
            this.chartFY.tooltip().format("{%Value}"); // Include the name, value, and percentage in the tooltip

            // Customize the tooltip title and content
            this.chartFY.tooltip()
                .titleFormat(
                    function () {
                        return '' + this.getData('x');
                    }
                )
                .format(
                    function () {
                        return `${FY} : ` + Anychart.format.number(this.getData('value'), {
                            groupsSeparator: ',',
                            decimalSeparator: '.'
                        }) + ` US$ ${valueDisplayOption}`;
                    }
                );

            // Set a white border around each slice
            this.chartFY.stroke("1 #FFFFFF");  

            // set the chart container
            this.chartFY.container(this.stage);

            // initiate drawing the chart
            this.chartFY.draw();

            // LY

            // create and configure the second pie chart (larger)
            this.chartLY = this.chart.pie(SeriesLY);

            // Set chart margin settings
            this.chartLY.padding(5, 5, 5, 5);

            // Configure labels
            this.chartLY.labels().position('outside');
            this.chartLY.labels().fontSize(17);

            this.chartLY.labels().format(
                function(){
                    // get Actual value of the text
                    let currentLabel = this.getData('x');
                    // let value = this.getData('value');
                    let percentage = this.getStat('percentValue').toFixed(2)+'%';

                    //return combined text
                    // return currentLabel; 
                    // return value; 
                    // return percentage;
                    return currentLabel+ " " + percentage; 
                }
            )
            

            // set chart legend settings
            this.chartLY.legend().enabled(false);

            // Adjust the bounds to make chartLY larger
            // validation if chartLY is larger 
            if(totalFY < totalLY ){
                // this.chartLY.labels().fontSize(12);
                this.chartLY.bounds("40%", "10%", "60%", "80%");
                
                // console.log('ly mayor fy');
            }
            else{
                // this.chartLY.bounds(0, "20%", "40%", "60%");
                //TODO  change font size for Validation 
                // this.chartLY.labels().fontSize(10);
                this.chartLY.bounds("50%", "25%", "50%", "50%");

                // console.log('ly menor fy');
            }

            this.chartLY.background().stroke(null);
            this.chartLY.innerRadius("30%");

            const LY = this.lastYear

            // chartLY Title
            this.chartLY
                .title()
                .enabled(true)
                .text(`${LY} US$ ${this.totalLY} ${this.valueDisplayOption}`)
                .orientation("bottom")
                .align("center")
                .fontSize(20)



            // create and configure a label
            // let labelLY = this.chart.standalones.label();
            // labelLY.text(LY);
            // labelLY.width("100%");
            // labelLY.height("100%");
            // labelLY.fontColor("#003A70");
            // labelLY.fontWeight("bold");
            // labelLY.hAlign("center");
            // labelLY.vAlign("middle");
            // // set the label as the center content
            // this.chartLY.center().content(labelLY);


            this.chartLY.label()
                .enabled(true) // Habilitar la etiqueta
                .text(LY) // Mostrar el valor de FY en la etiqueta
                .position("center") // Posicionar en el centro del gráfico
                .anchor("center") // Asegurar el anclaje en el centro
                .hAlign("center") // Alinear horizontalmente al centro
                .vAlign("middle") // Alinear verticalmente al centro
                .offsetY(-10)
                .fontColor("#003A70") // Establecer el color de la fuente
                .fontWeight("bold") // Establecer la fuente en negrita
                .fontSize(25); // Tamaño de la fuente


            // Configure tooltips
            this.chartLY.tooltip().title().fontColor("#FFFFFF"); // Set tooltip text color
            this.chartLY.tooltip().title().fontSize(14); // Set tooltip font size
            this.chartLY.tooltip().fontColor("#FFFFFF"); // Set tooltip text color
            this.chartLY.tooltip().fontSize(12); // Set tooltip font size
            this.chartLY.tooltip().padding(10); // Set tooltip padding
            this.chartLY.tooltip().format("{%Value}"); // Include the name, value, and percentage in the tooltip

            // Customize the tooltip title and content
            this.chartLY.tooltip()
                .titleFormat(
                    function () {
                        return '' + this.getData('x');
                    }
                )
                .format(
                    function () {
                        return `${LY} : ` + Anychart.format.number(this.getData('value'), {
                        groupsSeparator: ',',
                        decimalSeparator: '.'
                        }) + ` US$ ${valueDisplayOption}`;
                    }
                );

            // Set a white border around each slice
            this.chartLY.stroke("1 #FFFFFF");    

            // set the chart container
            this.chartLY.container(this.stage);
            // initiate drawing the chart
            this.chartLY.draw();


            //  TODO: Create Standalone interactive legend using both charts.  
            //  https://playground.anychart.com/docs/v8/samples/CS_Legend_Standalone_02
            //  For export to svg - using the legend from one chart is sufficient  
            // // // For now create a standalone legend
            // this.legend = this.chart.standalones.legend();

            // // set the source of legend items
            // this.legend.itemsSource([this.chartFY]);

            // // set the padding of the legend
            // this.legend.padding({ top: 10, bottom: 20 });
            // this.legend.position("bottom");
            // this.legend.itemsLayout("horizontal-expandable");

            // // set the container for the legend
            // this.legend.container(this.stage);

            // // draw the legend
            // this.legend.draw();

            this.chart.exports.filename('ST02_'+this.topicName+'_Market_Forecast_to_'+this.lastYear+"_by_"+this.segmentationName); //Change name of exports
            
        },

        exportToSvgEmit({width, height}){
            this.exportToSvg(width, height)
        },

        exportToSvg(width, height) {
            Anychart.exports.server("https://export.anychart.com");
            this.chart.exports.filename('ST02_'+this.topicName+'_Market_Forecast_to_'+this.lastYear+"_by_"+this.segmentationName); //Change name of exports
            let exportLabel = 'ST02_'+this.topicName+'_Market_Forecast_to_'+this.lastYear+"_by_"+this.segmentationName
            this.stage.saveAsSvg(width, height, exportLabel);
        },

        // EXPORT TO DOC 
        addChartsForWord(){
            const exportContent = {
                content: this.stage.toSvg(),
                // content: svgString ,
                name: 'VersusSegmentation '+ 'Figure2',
                segmentation: this.segmentationName,
                order: this.index1
            };
            this.$emit("addChartsForExportWord", {exportContent: exportContent, order:exportContent.order})
        },

        endLastStepForExportToWord(){
            this.$emit("finishLoading") // finish loading for export To Docx 
        },
    },
    watch: {
        // 'data': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             // console.log('outputFrom Chart Change1', newVal);
        //             if (this.chart) {
        //                 this.pie.dispose();
        //                 this.chart = null;
        //                 this.pie = null;
        //                 this.init();
        //             }
        //         }
        //     },
        //     deep: true,
        // },
        // 'year': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             // console.log('outputFrom Chart Change2', newVal);
        //             if (this.chart) {
        //                 this.pie.dispose();
        //                 this.chart = null;
        //                 this.pie = null;
        //                 this.init();
        //             }
        //         }
        //     },
        //     deep: true,
        // },
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart = null;
            this.pie = null;
            this.stage = null;
            this.chartFY = null;
            this.chartLY = null;
            this.legend = null;
        }
    }
}

</script>

<style scoped>
.chart{
    border: 1px solid #cfd8dc; /* Cambia el color y el tamaño del borde según sea necesario */
}
</style>
